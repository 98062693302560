<template>
  <div class="container">
    <!-- 标题 -->
    <div class="header" v-if="clickStauts">
      <img src="../assets/ok.png" alt="" />
      <div class="text">考试完成</div>
    </div>
    <div class="header" v-else></div>
    <!-- 中心主体 -->
    <div class="center">
      <!-- 标题 -->

      <div class="center-title">
        <h2 class="title-call">{{type==0?'拳术':'器械'}}技术视频录制</h2>
        <p class="title-callTwo">初段位武礼（背诵）、武术套路、武术散打</p>
      </div>
      <!-- 考试要求 -->
      <div class="require">考试要求</div>
      <div class="text-center">
        1.格式要求 <br />
        请考生以MP4格式横屏模式录制视频。<br />
        2.录制要求<br />
        （1）录制视频的场景没有规定，室内、室外、专业地毯或其他场地均可；但须保持光线充足、背景清静，不能有移动物体或杂物；可以有自然声音，不能有较大噪音。<br />
        （2）考生须手持A4纸打印的个人信息表放于胸前，并靠近录制镜头，不得佩戴口罩，将身体腰部以上部位展现在视频画面中，同时初段位考生背诵武礼，中段位考生静止保持10秒钟，结束后考生将个人信息表放于一旁回到技术考试场地开始技术考试，期间考生不得离开视频录制画面。<br />
        （3）技术考试时，考生身体须占据视频画面高度的2/3，同时保持视频画面稳定，录制中镜头只允许左右移动。
      </div>
    </div>
    <!-- 下载附件 -->
    <div class="downLoad">
      <div class="logo">
        <img src="../../src/assets/xingzung.png" alt="" />
      </div>
      <div class="adjunct" @click="myMessage">点击查看保存考生个人信息表</div>
    </div>
    <!-- 开始录制 -->
    <button
      class="start"
      id="nyInfo"
      @click="onVideo"
      :style="{ background: !clickStauts ? '#a62a2a' : '#CBCBCB' }"
      :disabled="clickStauts"
    >
      开始录制
    </button>
    <!-- accept="video/*" -->
    <input
      ref="inputVideo"
      type="file"
      name="video"
      id="video-input"
      accept="video/*"
      capture="camcorder"
      @change="videoChange($event)"
      hidden
    />

    <!-- 提示字符 -->
    <div class="hint">
      注：提交上传只有一次机会且视频时长不能超过7分钟，提交前请预览确认好您提交的作品，提交后不可更改！
    </div>
    <!-- 查看视频 -->
    <button
      class="lookVideo"
      :style="{ background: clickStauts ? '#a62a2a' : '#CBCBCB' }"
      :disabled="!clickStauts"
      @click="lookVideo"
    >
      查看提交视频
    </button>
  </div>
</template>
<script
  type="text/javascript"
  src="https://js.cdn.aliyun.dcloud.net.cn/dev/uni-app/uni.webview.1.5.2.js"
></script>
<script src="https://cdn.jsdelivr.net/npm/vue@2/dist/vue.js"></script>
<script
  type="text/javascript"
  src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js"
></script>
<script>
import axios from "axios";
export default {
  data() {
    return {
      time: null,
      submissionFlag: true, //录制视频按钮是否可用状态
      applyUserId: "",
      type: 0,
      videoPath: "",
      accessToken: "",
      url: "",
      clickStauts: false,
      duration: null,
      // baseUrl:'https://wushu.adinnet.cn',//外网测试地址
      baseUrl:'https://wsy-admin2.adinnet.cn',//生产地址
    };
  },
  mounted() {
    let url = window.location.href.split("?")[1];
    let params = new URLSearchParams(url);
    this.applyUserId = params.get("applyUserId");
    this.type = params.get("type");
    this.accessToken = params.get("accessToken");
  },
  methods: {
    videoChange(e) {
      // console.log(e,'获取文件1');
      // console.log(e.target.files,'获取文件2');
      this.$toast.loading({
        duration: 0,
        message: "视频上传中...",
        forbidClick: true,
        loadingType: "spinner",
      });
      // console.log(e.target.files[0],'第一步');
      let videoData = document.getElementById("nyInfo");
      let file = e.target.files[0];
      let url = URL.createObjectURL(file);
      // console.log(url,'第二步');
      let audioEle = new Audio(url);
      // console.log(audioEle,'第三步');
      let fileExt = file.name.split(".").pop().toLocaleLowerCase();
      let dataFile = new FormData();
      dataFile.append("file", file);
      // console.log(dataFile,'第四步');
      audioEle.muted = true
      audioEle.play().then(()=>audioEle.pause())
      this.wx.miniProgram.postMessage({ data: dataFile });
      audioEle.addEventListener("loadedmetadata", () => {
        this.duration = audioEle.duration;
        // console.log(this.duration,'第五步');
        if (this.duration > 420) {
          this.$toast.fail("视频时长超出!请重新录制");
          return false;
        } else {
          // console.log(dataFile,'第六步');
          axios.post(`${this.baseUrl}/api/common/uploadFile`, dataFile,
          {
            headers: {
              'Content-Type':'multipart/form-data'
            },
          }
          )
            .then((res) => {
              // console.log(res.data.data,'进接口');
              let videoPath = res.data.data;
              axios.post(`${this.baseUrl}/mini/rankApplyVideo/updateApplyVideo`,
                  {
                    applyUserId: this.applyUserId,
                    type: this.type,
                    // applyUserId: '27193',
                    // type: '0',
                    videoPath: videoPath,
                  },
                  {
                    headers: {
                      'Content-Type': 'application/json; charset=UTF-8' ,
                      accessToken: this.accessToken,
                      // accessToken: 'MINI_LOGIN_USER_7d0dcfd1bc1e475493fa8ca869bc7748',
                    },
                  }
                )
                .then((result) => {
                  if (result.data.code == 200) {
                    this.$toast.clear();
                    this.$toast.success("上传成功");
                    this.clickStauts = true;
                  }
                })
                .catch((err) => {
                  this.$toast.clear();
                  this.$toast.fail("上传失败");
                });
            })
            .catch((err) => {
              console.log(err,'>>>>>>>>>>>>>>');
            });
        }
      });
    },

    //点击button实现调起录制摄像头
    onVideo() {
      //点击事件
      this.$dialog.confirm({
        message: `请确认是否已打印考生个人信息表？如因无法打印，也可以手写一遍个人信息表内容进行展示。`,
        confirmButtonText: '是',
        confirmButtonColor: '#A62A2A',
        cancelButtonText: '否',
        cancelButtonColor:'#626366'
      }).then(() => {
        this.$refs.inputVideo.click();
      }).catch(()=>{
        console.log('取消');
      });
    },
    myMessage() {
      //查看保存个人信息
      this.wx.miniProgram.redirectTo({
        url: "/pages/personalDetails/personalDetails",
      });
    },
    lookVideo() {
      this.wx.miniProgram.redirectTo({
        url: "/pages/videoEdorPage/videoEdorPage",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100vw;
  height: 100vh;
  background: #fcf8f5;
  background-image: url(../../src/assets/bg.png);
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  overflow-y: auto;
  .header {
    position: relative;
    width: 100%;
    height: 130px;
    // background: grey;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1rpx solid black;
    // z-index: 9999;
    img {
      width: 39px;
      height: 39px;
    }
    .text {
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #20b759;
    }
  }
  .center {
    width: 95%;
    height: 400px;
    background: #ffffff;
    box-shadow: 0px 0px 5px 0px rgba(75, 27, 27, 0.08);
    border-radius: 3px;
    margin: 0 auto;
    overflow-y: auto;
    box-sizing: border-box;
    .center-title {
      width: 100%;
      height: 32px;
      margin: 0 auto;
      // border: 1px solid black;
      padding: 0 15px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .title-call {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 700;
        color: #25272b;
        line-height: 32px;
      }
      .title-callTwo {
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a62a2a;
        margin-left: 10px;
        line-height: 32px;
      }
    }
    .require {
      width: 100px;
      height: 23px;
      background: linear-gradient(
        90deg,
        #a62a2a 0%,
        #a62a2a 47%,
        rgba(255, 255, 255, 0) 100%
      );
      margin-top: 10px;
      color: #ffffff;
    }
    .text-center {
      width: 95%;
      height: 300px;
      margin: 10px auto;
      // border: 1px solid red;
      overflow-y: scroll;
      text-align: left;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #25272b;
    }
  }
  .downLoad {
    width: 95%;
    height: 30px;
    // border: 1px solid red;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px auto;
    .logo {
      width: 18px;
      height: 15px;
      // background: blue;
      img {
        width: 100%;
        height: 100%;
        vertical-align: baseline;
      }
    }
    .adjunct {
      // width: 200px;
      // height: 30px;
      font-size: 12px;
      // line-height: 30px;
      margin-left: 10px;
    }
  }
  .start {
    width: 95%;
    height: 50px;
    margin: 16px auto;
    border-radius: 3px;
    text-align: center;
    line-height: 50px;
    font-size: 15px;
    border: none;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    input {
      width: 50%;
    }
  }
  // 视频录制完成状态样式
  .end {
    background: #cbcbcb;
  }
  .hint {
    width: 95%;
    height: 30px;
    margin: 8px auto;
    text-align: left;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a62a2a;
  }
  .lookVideo {
    width: 95%;
    height: 50px;
    margin: 16px auto;
    border: none;
    font-size: 15px;
    border-radius: 3px;
    text-align: center;
    line-height: 50px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>
