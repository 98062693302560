import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
// import Element from "element-ui";
import { Toast , Dialog } from 'vant';
import 'vant/lib/index.css';
// import request from "../commom/until/request.js"
// import until from "../commom/until/until.js"
// Vue.prototype.$http = axios
// Vue.prototype.$http = request
// Vue.prototype.$http = until
// import VConsole from 'vconsole'
// let vconsole = new VConsole()
let wx = require('weixin-js-sdk')
Vue.prototype.wx = wx
Vue.config.productionTip = false
Vue.use(Toast);
Vue.use(Dialog)

new Vue({
  // vconsole,

  render: h => h(App),
}).$mount('#app')
