<template>
  <div id="app"><CameareView /></div>
</template>

<script>
import CameareView from "./views/CameareView.vue";

export default {
  name: "App",
  components: {
    CameareView,
  },
};
</script>

<style>
body{
  margin: 0;
  padding: 0;
  font-family: PingFangSC-Regular, PingFang SC;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
